import {
  activityTypes,
  activityStatuses,
  callTypes,
  callsOutcomes,
  communicationPreferenceOptions,
  flowStatuses,
  genders,
  languages,
  noteMethods,
  onboardingFlows,
  outreachTypes,
  patientConditionTypes,
  timeZones,
} from '@ownhealthil/urine-enums';

export const ENV_TYPES = {
  DEVELOP: 'develop',
  STAGING: 'staging',
  PROD_US: 'prod-us',
  PROD_UK: 'prod-uk',
};

export const ENV_LABELS = {
  [ENV_TYPES.DEVELOP]: 'HEART Develop',
  [ENV_TYPES.STAGING]: 'HEART Staging',
  [ENV_TYPES.PROD_US]: 'HEART US',
  [ENV_TYPES.PROD_UK]: 'HEART UK',
};

export const PROD_ADMINS = [
  'katie.williams@healthy.io',
  'alison.adams@healthy.io',
  'rebecca.forbes@healthy.io',
  'william.sutherland@healthy.io',
  'stephen.thompson@healthy.io',
  'callum.stranks@healthy.io',

  'talya.sassoon@healthy.io',
  'yarden.molakandov@healthy.io',
  'luke.lacroix@healthy.io',
  'miri.eyni@healthy.io',
  'avi.peleg@healthy.io',

  'guido.senderowitsch@healthy.io',
  'guido@healthy.io',
  'jen.la@healthy.io',

  'urine.backend@healthy.io',
  'urinecommercialautomation@healthy.io',
  'zoe.botta@healthy.io',
  'tammy.damato@healthy.io',
  'madelyn.palmer@healthy.io',
];

export const QUERY_KEYS = {
  IMPORT: {
    FILES: '[Import] Files',
    FILES_IN_PROGRESS: '[Import] Files in progress',
    FILE_REPORT: '[Import] File report',
  },
  ONBOARDING: {
    COHORT: '[Onboarding] Cohort',
    ONBOARDING_FLOWS: '[Onboarding] Onboarding flows',
    ORG_UNITS: '[Onboarding] Organization units',
  },
  PARTNER: {
    CONFIG: '[Partner] Config',
    ORG_UNITS: '[Partner] Organization units',
    TAGS: '[Partner] Tags',
  },
  ORG_UNITS: {
    TAGS: '[OrgUnits] Tags',
  },
  PATIENT_LIST: {
    PATIENTS: '[PatientList] Patients',
  },
  PCP: {
    PATIENTS: '[Pcp] Patients',
  },
};

export const ERRORS = {
  DUPLICATE_PHONE_NUMBER: 'DUPLICATE_PHONE_NUMBER',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_INPUT: 'INVALID_INPUT',
  MISSING_PHONE_NUMBER: 'MISSING_PHONE_NUMBER',
};

export const ERROR_LABELS = {
  [ERRORS.DUPLICATE_PHONE_NUMBER]:
    "Failed to update the patient. The app's unique identity is already attached to another patient.",
  [ERRORS.INVALID_ADDRESS]: 'Invalid address',
  [ERRORS.INVALID_INPUT]: 'Invalid input',
  [ERRORS.MISSING_PHONE_NUMBER]: 'Failed to send SMS. Phone number is missing',
};

export const QUEUE_STATES = {
  CONSENTED: 'consented',
  COOLING_OFF: 'cooling_off',
  DECLINED: 'declined',
  INELIGIBLE: 'ineligible',
  NO_RESPONSE: 'no_response',
  OPTED_OUT: 'opted_out',
  PENDING_CONSENT: 'pending_consent',
  PENDING_CONSENT_WITH_KIT: 'pending_consent_with_kit',
  PENDING_FOLLOW_UP_TEST: 'pending_follow_up_test',
  PENDING_KIT_FULFILLMENT: 'pending_kit_fulfillment',
  PENDING_ONBOARDING: 'pending_onboarding',
  PENDING_PRE_APPROVAL: 'pending_pre_approval',
  PENDING_SERVICE_QUALIFICATION: 'pending_service_qualification',
  PENDING_TEST: 'pending_test',
  PENDING_WARM_UP: 'pending_warm_up',
  TEST_COMPLETED: 'test_completed',
  TEST_ERROR: 'test_error',
  TEST_FAILED: 'test_failed',
  SERVICE_ENDED: 'service_ended',
  UNAUTHORIZED: 'unauthorized',
};

export const QUEUE_STATE_LABELS = {
  [QUEUE_STATES.CONSENTED]: 'Consented',
  [QUEUE_STATES.COOLING_OFF]: 'Cooling off',
  [QUEUE_STATES.DECLINED]: 'Opt out - declined',
  [QUEUE_STATES.INELIGIBLE]: 'Ineligible',
  [QUEUE_STATES.OPTED_OUT]: 'Opted out',
  [QUEUE_STATES.PENDING_CONSENT]: 'Pending consent',
  [QUEUE_STATES.PENDING_FOLLOW_UP_TEST]: 'Pending follow-up test',
  [QUEUE_STATES.PENDING_ONBOARDING]: 'Pending onboarding',
  [QUEUE_STATES.PENDING_PRE_APPROVAL]: 'Pending pre-approval',
  [QUEUE_STATES.PENDING_SERVICE_QUALIFICATION]: 'Pending service qualification',
  [QUEUE_STATES.PENDING_TEST]: 'Pending test',
  [QUEUE_STATES.TEST_COMPLETED]: 'Test completed',
  [QUEUE_STATES.TEST_FAILED]: 'Test failed',
  [QUEUE_STATES.UNAUTHORIZED]: 'Unauthorized',
  [QUEUE_STATES.PENDING_CONSENT_WITH_KIT]: 'Pending consent with kit',
  [QUEUE_STATES.PENDING_KIT_FULFILLMENT]: 'Pending kit fulfillment',
  [QUEUE_STATES.PENDING_WARM_UP]: 'Pending warmup',
  [QUEUE_STATES.TEST_ERROR]: 'Test error',
  [QUEUE_STATES.NO_RESPONSE]: 'No response',
  [QUEUE_STATES.SERVICE_ENDED]: 'Service ended',
};

export const FLOW_STATUS_LABELS = {
  [flowStatuses.CONSENTED]: 'Consented',
  [flowStatuses.COOLING_OFF]: 'Cooling off',
  [flowStatuses.DECLINED]: 'Opt out - declined',
  [flowStatuses.INELIGIBLE]: 'Ineligible',
  [flowStatuses.OPTED_OUT]: 'Opted out',
  [flowStatuses.PENDING_CONSENT]: 'Pending consent',
  [flowStatuses.PENDING_FOLLOWUP_TEST]: 'Pending follow-up test',
  [flowStatuses.PENDING_ONBOARDING]: 'Pending onboarding',
  [flowStatuses.PENDING_PRE_APPROVAL]: 'Pending pre-approval',
  [flowStatuses.PENDING_SERVICE_QUALIFICATION]: 'Pending service qualification',
  [flowStatuses.PENDING_TEST]: 'Pending test',
  [flowStatuses.TEST_COMPLETED]: 'Test completed',
  [flowStatuses.TEST_FAILED]: 'Test failed',
  [flowStatuses.UNAUTHORIZED]: 'Unauthorized',
};

export const CALL_TYPE_LABELS = {
  [callTypes.CONSENT]: 'Consent',
  [callTypes.REMINDER]: 'Reminder',
  [callTypes.SUPPORT]: 'Support',
  [callTypes.PCP_CONFIRMATION]: 'PCP confirmation',
  [callTypes.TEST_FAILED]: 'Test failed',
  [callTypes.POST_TEST]: 'Post test',
};

export const ACTIVITY_LABELS = {
  [activityTypes.APP_VERIFICATION]: 'App Verification',
  [activityTypes.EMAIL]: 'Email',
  [activityTypes.LETTER]: 'Letter',
  [activityTypes.OUTGOING_SMS]: 'Outgoing SMS',
  [activityTypes.INCOMING_SMS]: 'Incoming SMS',
  [activityTypes.OUTGOING_CALL]: 'Outgoing Call',
  [activityTypes.INCOMING_CALL]: 'Incoming Call',
  [activityTypes.TEST_RESULT]: 'Test Result',
  [activityTypes.KIT_SHIPMENT]: 'Kit Shipment',
  [activityTypes.NOTE]: 'Note',
  [activityTypes.PCP_FAX_CONFIRMATION]: 'PCP Fax Number Confirmation',
  [activityTypes.PCP_NAME_CONFIRMATION]: 'PCP Full Name Confirmation',
  [activityTypes.PCP_NPI_CONFIRMATION]: 'PCP NPI Number Confirmation',
  [activityTypes.PRIORITIZED]: 'Prioritized',
  [activityTypes.PUSH_NOTIFICATION]: 'Push Notification',
  [activityTypes.SUPPORT_TICKET]: 'Support Ticket',
  [activityTypes.RESULTS_DELIVERY_TO_DOCTOR]: 'Results Delivery to Doctor',
};

export const ACTIVITY_STATUS_LABELS = {
  [activityStatuses.CLICKED]: 'Clicked',
  [activityStatuses.ERROR]: 'Error',
  [activityStatuses.SENT]: 'Sent',
  [activityStatuses.SCHEDULED]: 'Sent to Provider',
  [activityStatuses.PENDING]: 'Pending to be Sent',
  [activityStatuses.OPENED]: 'Opened',
  [activityStatuses.CANCELED]: 'Canceled',
  [activityStatuses.REFUSED]: 'Refused by Customer',
  [activityStatuses.REJECTED]: 'Rejected',
  [activityStatuses.RESOLVED]: 'Resolved',
  [activityStatuses.DELAYED]: 'Delayed',
  [activityStatuses.ANSWERED]: 'Patient Answered',
  [activityStatuses.NOT_ANSWERED]: "Patient Didn't Answer",
  [activityStatuses.NOT_ANSWERED_VOICEMAIL_LEFT]:
    "Patient Didn't Answer, VoiceMail was Left",
  [activityStatuses.UNREACHABLE]: 'Patient Unreachable',
  [activityStatuses.UNSUBSCRIBED]: 'Unsubscribed',
  [activityStatuses.COMPLETED]: 'Completed',
  [activityStatuses.ARRIVED]: 'Arrived',
  [activityStatuses.PENDING_PATIENT_VERIFICATION]: "Patient isn't verified yet",
  [activityStatuses.PATIENT_VERIFIED]: 'Patient Verified',
  [activityStatuses.WRONG_ADDRESS]: 'Wrong Address',
  [activityStatuses.SUCCESS]: 'Success',
  [activityStatuses.NO_PCP]: 'No PCP',
  [activityStatuses.OPTED_OUT_FROM_PCP]:
    'Patient opted out from sharing the results',
  [activityStatuses.NOT_DELIVERED]: 'Not Delivered',
  [activityStatuses.RETURNED]: 'Returned',
  [activityStatuses.RETURNING]: 'Return in Progress',
  [activityStatuses.SPAM_REPORTED]: 'Reported as spam',
};

export const ACTIVITY_STATUS_SKIN = {
  [activityStatuses.ANSWERED]: 'success',
  [activityStatuses.ARRIVED]: 'success',
  [activityStatuses.OPENED]: 'success',
  [activityStatuses.SENT]: 'success',
  [activityStatuses.COMPLETED]: 'success',
  [activityStatuses.ERROR]: 'danger',
  [activityStatuses.UNREACHABLE]: 'danger',
  [activityStatuses.REJECTED]: 'danger',
  [activityStatuses.DELAYED]: 'warning',
  [activityStatuses.SCHEDULED]: 'warning',
  [activityStatuses.PATIENT_VERIFIED]: 'success',
  [activityStatuses.REFUSED]: 'danger',
  [activityStatuses.WRONG_ADDRESS]: 'danger',
  [activityStatuses.SUCCESS]: 'success',
};

export const ONBOARDING_FLOW_TYPE_LABELS = {
  [onboardingFlows.CALL_FIRST]: 'Call flow',
  [onboardingFlows.KIT_FIRST]: 'Kit flow',
};

export const CALL_DISPOSITION = {
  ANSWERED: 'answered',
  UNANSWERED: 'unanswered',
};

export const CALL_DISPOSITION_LABELS = {
  [CALL_DISPOSITION.ANSWERED]: 'Call Answered',
  [CALL_DISPOSITION.UNANSWERED]: 'Call Unanswered',
};

export const OUTCOME_LABELS = {
  [callsOutcomes.CALL_LATER]: "Can't talk - Call later",
  [callsOutcomes.CONSENTED]: 'Consented - Interested in the test',
  [callsOutcomes.DECLINED]: 'Opt out - Declined',
  [callsOutcomes.DO_NOT_CALL]: 'Do not call',
  [callsOutcomes.INCOMPLETE_CALL]: 'Incomplete call',
  [callsOutcomes.INELIGIBLE]: 'Ineligible',
  [callsOutcomes.INDETERMINATE]: 'Indeterminate',
  [callsOutcomes.NO_VOICEMAIL_LEFT]: 'No VM left',
  [callsOutcomes.NOTE_ONLY]: 'Note only',
  [callsOutcomes.REMINDER_COMPLETED]: 'Reminder call completed',
  [callsOutcomes.SUPPORT_COMPLETED]: 'Support call completed',
  [callsOutcomes.PCP_CONFIRMATION_COMPLETED]: 'PCP confirmation call completed',
  [callsOutcomes.TEST_FAILED_COMPLETED]: 'Test failed call completed',
  [callsOutcomes.CONFIRMED_POST_TEST_CTA_COMPLETION]:
    'Already completed post test CTA',
  [callsOutcomes.POST_TEST_COMPLETED]: "Didn't complete post test CTA yet",
  [callsOutcomes.UNREACHABLE_ANSWERED]: 'Answered - Unreachable',
  [callsOutcomes.UNREACHABLE_UNANSWERED]: 'Unreachable',
  [callsOutcomes.VOICEMAIL_LEFT]: 'Left VM',
};

export const ELABORATIONS = {
  ALL_NUMBERS_ARE_WRONG: 'All provided numbers are wrong',
  ALREADY_DID_ACR_TEST: 'Already did an ACR test in the past 12 months',
  ALREADY_COMPLETED_POST_TEST_CTA: 'Already completed post-test CTA',
  ALREADY_SEEING_KIDNEY_DOCTOR: 'Already seeing a kidney doctor',
  ALREADY_TESTED_NOW_DECLINES:
    'Patient tried the test but now declines service',
  ANSWERING_MACHINE_NOT_SET_UP: 'Answering machine is not set up',
  AT_WORK: 'At work',
  BRING_SAMPLE: 'Would rather bring a sample to surgery',
  BUSY: 'Busy',
  BUSY_TONE: 'Busy tone',
  CALL_TRANSFERRED_TO_BILINGUAL_PEA: 'Call transferred to bilingual PEA',
  CANT_GET_THROUGH: "Can't get through to the patient",
  CARE_HOME_RESIDENT: 'Care home resident',
  COMPLETED_TEST_WANTS_TO_BE_REMOVED:
    'Patient completed the test and wants details removed',
  COUNTRY_PRACTICE_HOSPITAL:
    'Out of country / no longer at practice / in hospital',
  CLINICALLY_INELIGIBLE_UK:
    'Clinically ineligible (e.g. pregnant, not diabetic, on dialysis, end of life, catheter-in-situ, unable to provide a sample, incontinent)',
  DECEASED: 'Deceased',
  DOES_NOT_OWNS_SMARTPHONE_WILL_BORROW:
    'Does not own a smartphone, will borrow one',
  DISENROLLED_FROM_NETWORK: 'Disenrolled from the network',
  DOES_NOT_SPEAK_ENGLISH: 'Does not speak English',
  DOES_NOT_SPEAK_ENGLISH_SPANISH: 'Does not speak English or Spanish',
  DONT_CALL: 'Do not call',
  DONT_CALL_AGAIN: 'Does not want to be called again',
  DONT_CALL_HUNG_UP: 'Do not call / Hung up',
  DRIVING: 'Driving',
  GAVE_SAMPLE_HAVE_APPOINTMENT:
    'Just gave a sample / Already have an appointment',
  HANG_UP_BEFORE_CONNECTION: 'Hang up before connection',
  HUNG_UP_AFTER_CONNECTION: 'Hung up after connection',
  HUNG_UP_PRIOR_TO_VALIDATION: 'Hung up prior to validation',
  HUNG_UP_WHILE_WAITING_FOR_INTERPRETER:
    'Hung up while waiting for the interpreter',
  INCOMPATIBLE_PHONE: 'Incompatible phone',
  INVALID_NUMBER: 'Invalid number',
  LACK_OF_CONFIDENCE: 'Lack of confidence with apps and self testing tests',
  OWNS_A_SMARTPHONE_LOW_DIGITAL_LITERACY:
    'Owns a smartphone, Low Digital Literacy',
  LOW_DIGITAL_LITERACY_AND_REFUSED_PEA_ASSISTANCE:
    'Low digital literacy & refused PEA assistance',
  MEMBER_IS_BUSY: 'Member is busy',
  MEMBER_IS_ASSISTED_LIVING:
    'Member is in assisted living facility or nursing home',
  MEDICAL_QUESTIONS_OR_CLARIFICATIONS:
    'Medical Questions or Clarifications Involved',
  NEED_TO_SPEAK_WITH_PCP: 'Need to speak with PCP',
  NO_SMARTPHONE_ACCESS: "Don't have access to a smartphone",
  NO_SMARTPHONE_AND_WONT_USE:
    'Does not have a smartphone and will not use someone else’s',
  NO_RING_DEAD_AIR: 'Does not ring / Dead air',
  NOT_INTERESTED: 'Not interested',
  NOT_IN_SERVICE_DISCONNECTED: 'Number no longer in service / disconnected',
  NO_VOICEMAIL: 'Answering machine did not come on',
  NO_VOICEMAIL_OPTION: 'No voice mail option',
  NON_MEMBER_PHONE:
    'Non-member phone number provided (spouse, another family member)',
  OPTED_OUT_BY_GP: 'Opted out via GP',
  OPTED_OUT_BY_SMS: 'Opted out via SMS',
  OTHER: 'Other',
  OUT_OF_COUNTRY_AWAY_FROM_HOME: 'Out of the country / Away from home',
  AWAY_FROM_HOME: 'Away from home for more than 60 days',
  PATIENT_DETAILS_INCORRECT: 'Patient details incorrect',
  PCP_INVOLVEMENT: 'PCP involvement',
  PCP_INVOLVEMENT_QUESTIONS: 'Questions Related to PCP Involvement',
  PCP_DETAILS_VERIFIED: 'PCP details verified',
  PCP_DETAILS_NOT_VERIFIED: "Couldn't verify PCP details",
  PREFER_HEALTH_TESTS:
    'Prefers to health tests with doctor/upcoming appointment with doctor',
  PRIVACY_CONCERNS: 'Privacy concerns',
  RECENT_APPOINTMENT: 'Recent or upcoming appointment/lab work',
  RECENTLY_COMPLETED_ACR: 'Recently completed ACR urine test',
  REFUSED_TRANSLATION_LINE: 'Refused translation line',
  REPORTS_MEDICAL_REASON_FOR_NOT_TESTING:
    'Reports medical reason for not testing',
  REPORTS_THEY_WILL_COMPLETE_POST_TEST_CTA:
    'Reports they will complete post-test CTA',
  SECURITY_CONCERNS: 'Concerned about information security',
  SELECTION_CONCERNS: 'Concerns around the Selection Process',
  TRANSFERRED_TO_AFFILIATED_PROGRAM: 'Transferred to affiliated program',
  TROUBLE_WITH_APP:
    'Not good with tech or apps / Had trouble downloading the app',
  UNABLE_READ_OR_WRITE: 'Unable to read or write',
  UNDER_KIDNEY_SPECIALIST_CARE:
    'Under the care of a specialist who checks kidneys',
  UNSURE_IF_ANSWERING_MACHINE_CAME_ON: 'Unsure if answering machine came on',
  VOICEMAIL_DOES_NOT_MATCH: 'VM greeting name does not match member',
  VOICEMAIL_FULL: 'Answering machine full',
  VOICEMAIL_NOT_NECESSARY: 'VM not necessary',
  WAITING_TO_RECEIVE_OR_REVIEW_KIT: 'Waiting to receive/review kit',
  WANTS_TO_CONSULT_WITH_FAMILY_MEMBER_OR_FRIEND:
    'Wants to consult with family member/friend',
  WANTS_TO_GET_MORE_INFO_ABOUT_SERVICE:
    'Wants to get more information about our service',
  WILL_NOT_COMPLETE_POST_TEST_CTA: 'Will not complete post-test CTA',
  WONT_CONFIRM_IDENTITY_OVER_PHONE:
    'Member will not confirm identity over phone',
  WRONG_NAME_IN_VOICEMAIL: 'Wrong name in VM greeting',
  WRONG_NUMBER: 'Wrong number',
  SPOKE_TO_NON_MEMBER_ABOUT_CALL_BACK: 'Spoke to non-member about call back',
  SPOKE_TO_NON_MEMBER_CANT_SPEAK_TO_MEMBER:
    'Spoke to non-member: Can not speak to member',
};

export const OUTCOME_HINT = {
  BAD_NUMBER: 'Bad number - No need to use this number again',
  CANNOT_BE_REACHED_BY_PHONE: 'Member cannot be reached by phone',
  DOES_NOT_MEET_INCLUSION_CRITERIA:
    'Members who do not meet the service’s inclusion criteria',
  DO_NOT_CALL: 'Member asks not to call again',
  INTEREST_IN_DOING_TEST: 'Member shows interest in doing the test',
  MEET_INCLUSION_CRITERIA:
    "Members who meet our initial inclusion criteria but don't want to take part",

  NEED_ANOTHER_CONSENT_CALL:
    "Member didn't say if he will do or won't do the test - need another consent call",
  NO_CALL_MADE: 'No call was made - add a note to the activity log',
  REMINDED_TO_DO_TEST: 'Member was reminded to do the test',
  REQUESTED_SCHEDULE: 'Member requested to schedule a call at a later time',
};

export const US_STATES = [
  { abbreviation: 'AL', name: 'Alabama' },
  { abbreviation: 'AK', name: 'Alaska' },
  { abbreviation: 'AS', name: 'American Samoa' },
  { abbreviation: 'AZ', name: 'Arizona' },
  { abbreviation: 'AR', name: 'Arkansas' },
  { abbreviation: 'CA', name: 'California' },
  { abbreviation: 'CO', name: 'Colorado' },
  { abbreviation: 'CT', name: 'Connecticut' },
  { abbreviation: 'DE', name: 'Delaware' },
  { abbreviation: 'DC', name: 'District of Columbia' },
  { abbreviation: 'FM', name: 'Federated States of Micronesia' },
  { abbreviation: 'FL', name: 'Florida' },
  { abbreviation: 'GA', name: 'Georgia' },
  { abbreviation: 'GU', name: 'Guam' },
  { abbreviation: 'HI', name: 'Hawaii' },
  { abbreviation: 'ID', name: 'Idaho' },
  { abbreviation: 'IL', name: 'Illinois' },
  { abbreviation: 'IN', name: 'Indiana' },
  { abbreviation: 'IA', name: 'Iowa' },
  { abbreviation: 'KS', name: 'Kansas' },
  { abbreviation: 'KY', name: 'Kentucky' },
  { abbreviation: 'LA', name: 'Louisiana' },
  { abbreviation: 'ME', name: 'Maine' },
  { abbreviation: 'MH', name: 'Marshall Islands' },
  { abbreviation: 'MD', name: 'Maryland' },
  { abbreviation: 'MA', name: 'Massachusetts' },
  { abbreviation: 'MI', name: 'Michigan' },
  { abbreviation: 'MN', name: 'Minnesota' },
  { abbreviation: 'MS', name: 'Mississippi' },
  { abbreviation: 'MO', name: 'Missouri' },
  { abbreviation: 'MT', name: 'Montana' },
  { abbreviation: 'NE', name: 'Nebraska' },
  { abbreviation: 'NV', name: 'Nevada' },
  { abbreviation: 'NH', name: 'New Hampshire' },
  { abbreviation: 'NJ', name: 'New Jersey' },
  { abbreviation: 'NM', name: 'New Mexico' },
  { abbreviation: 'NY', name: 'New York' },
  { abbreviation: 'NC', name: 'North Carolina' },
  { abbreviation: 'ND', name: 'North Dakota' },
  { abbreviation: 'MP', name: 'Northern Mariana Islands' },
  { abbreviation: 'OH', name: 'Ohio' },
  { abbreviation: 'OK', name: 'Oklahoma' },
  { abbreviation: 'OR', name: 'Oregon' },
  { abbreviation: 'PW', name: 'Palau' },
  { abbreviation: 'PA', name: 'Pennsylvania' },
  { abbreviation: 'PR', name: 'Puerto Rico' },
  { abbreviation: 'RI', name: 'Rhode Island' },
  { abbreviation: 'SC', name: 'South Carolina' },
  { abbreviation: 'SD', name: 'South Dakota' },
  { abbreviation: 'TN', name: 'Tennessee' },
  { abbreviation: 'TX', name: 'Texas' },
  { abbreviation: 'UT', name: 'Utah' },
  { abbreviation: 'VT', name: 'Vermont' },
  { abbreviation: 'VI', name: 'Virgin Islands' },
  { abbreviation: 'VA', name: 'Virginia' },
  { abbreviation: 'WA', name: 'Washington' },
  { abbreviation: 'WV', name: 'West Virginia' },
  { abbreviation: 'WI', name: 'Wisconsin' },
  { abbreviation: 'WY', name: 'Wyoming' },
];

export const UK_STATES = [
  { abbreviation: 'I0', name: 'Aberconwy and Colwyn' },
  { abbreviation: 'I1', name: 'Aberdeen City' },
  { abbreviation: 'I2', name: 'Aberdeenshire' },
  { abbreviation: 'I3', name: 'Anglesey' },
  { abbreviation: 'I4', name: 'Angus' },
  { abbreviation: 'I5', name: 'Antrim' },
  { abbreviation: 'I6', name: 'Argyll and Bute' },
  { abbreviation: 'I7', name: 'Armagh' },
  { abbreviation: 'I8', name: 'Avon' },
  { abbreviation: 'I9', name: 'Ayrshire' },
  { abbreviation: 'IB', name: 'Bath and NE Somerset' },
  { abbreviation: 'IC', name: 'Bedfordshire' },
  { abbreviation: 'IE', name: 'Belfast' },
  { abbreviation: 'IF', name: 'Berkshire' },
  { abbreviation: 'IG', name: 'Berwickshire' },
  { abbreviation: 'IH', name: 'BFPO' },
  { abbreviation: 'II', name: 'Blaenau Gwent' },
  { abbreviation: 'IJ', name: 'Buckinghamshire' },
  { abbreviation: 'IK', name: 'Caernarfonshire' },
  { abbreviation: 'IM', name: 'Caerphilly' },
  { abbreviation: 'IO', name: 'Caithness' },
  { abbreviation: 'IP', name: 'Cambridgeshire' },
  { abbreviation: 'IQ', name: 'Cardiff' },
  { abbreviation: 'IR', name: 'Cardiganshire' },
  { abbreviation: 'IS', name: 'Carmarthenshire' },
  { abbreviation: 'IT', name: 'Ceredigion' },
  { abbreviation: 'IU', name: 'Channel Islands' },
  { abbreviation: 'IV', name: 'Cheshire' },
  { abbreviation: 'IW', name: 'City of Bristol' },
  { abbreviation: 'IX', name: 'Clackmannanshire' },
  { abbreviation: 'IY', name: 'Clwyd' },
  { abbreviation: 'IZ', name: 'Conwy' },
  { abbreviation: 'J0', name: 'Cornwall/Scilly' },
  { abbreviation: 'J1', name: 'Cumbria' },
  { abbreviation: 'J2', name: 'Denbighshire' },
  { abbreviation: 'J3', name: 'Derbyshire' },
  { abbreviation: 'J4', name: 'Derry/Londonderry' },
  { abbreviation: 'J5', name: 'Devon' },
  { abbreviation: 'J6', name: 'Dorset' },
  { abbreviation: 'J7', name: 'Down' },
  { abbreviation: 'J8', name: 'Dumfries and Galloway' },
  { abbreviation: 'J9', name: 'Dunbartonshire' },
  { abbreviation: 'JA', name: 'Dundee' },
  { abbreviation: 'JB', name: 'Durham' },
  { abbreviation: 'JC', name: 'Dyfed' },
  { abbreviation: 'JD', name: 'East Ayrshire' },
  { abbreviation: 'JE', name: 'East Dunbartonshire' },
  { abbreviation: 'JF', name: 'East Lothian' },
  { abbreviation: 'JG', name: 'East Renfrewshire' },
  { abbreviation: 'JH', name: 'East Riding Yorkshire' },
  { abbreviation: 'JI', name: 'East Sussex' },
  { abbreviation: 'JJ', name: 'Edinburgh' },
  { abbreviation: 'JK', name: 'England' },
  { abbreviation: 'JL', name: 'Essex' },
  { abbreviation: 'JM', name: 'Falkirk' },
  { abbreviation: 'JN', name: 'Fermanagh' },
  { abbreviation: 'JO', name: 'Fife' },
  { abbreviation: 'JP', name: 'Flintshire' },
  { abbreviation: 'JQ', name: 'Glasgow' },
  { abbreviation: 'JR', name: 'Gloucestershire' },
  { abbreviation: 'JS', name: 'Greater London' },
  { abbreviation: 'JT', name: 'Greater Manchester' },
  { abbreviation: 'JU', name: 'Gwent' },
  { abbreviation: 'JV', name: 'Gwynedd' },
  { abbreviation: 'JW', name: 'Hampshire' },
  { abbreviation: 'JX', name: 'Hartlepool' },
  { abbreviation: 'HAW', name: 'Hereford and Worcester' },
  { abbreviation: 'JY', name: 'Hertfordshire' },
  { abbreviation: 'JZ', name: 'Highlands' },
  { abbreviation: 'K0', name: 'Inverclyde' },
  { abbreviation: 'K1', name: 'Inverness-Shire' },
  { abbreviation: 'K2', name: 'Isle of Man' },
  { abbreviation: 'K3', name: 'Isle of Wight' },
  { abbreviation: 'K4', name: 'Kent' },
  { abbreviation: 'K5', name: 'Kincardinshire' },
  { abbreviation: 'K6', name: 'Kingston Upon Hull' },
  { abbreviation: 'K7', name: 'Kinross-Shire' },
  { abbreviation: 'K8', name: 'Kirklees' },
  { abbreviation: 'K9', name: 'Lanarkshire' },
  { abbreviation: 'KA', name: 'Lancashire' },
  { abbreviation: 'KB', name: 'Leicestershire' },
  { abbreviation: 'KC', name: 'Lincolnshire' },
  { abbreviation: 'KD', name: 'Londonderry' },
  { abbreviation: 'KE', name: 'Merseyside' },
  { abbreviation: 'KF', name: 'Merthyr Tydfil' },
  { abbreviation: 'KG', name: 'Mid Glamorgan' },
  { abbreviation: 'KI', name: 'Mid Lothian' },
  { abbreviation: 'KH', name: 'Middlesex' },
  { abbreviation: 'KJ', name: 'Monmouthshire' },
  { abbreviation: 'KK', name: 'Moray' },
  { abbreviation: 'KL', name: 'Neath & Port Talbot' },
  { abbreviation: 'KM', name: 'Newport' },
  { abbreviation: 'KN', name: 'Norfolk' },
  { abbreviation: 'KP', name: 'North Ayrshire' },
  { abbreviation: 'KQ', name: 'North East Lincolnshire' },
  { abbreviation: 'KR', name: 'North Lanarkshire' },
  { abbreviation: 'KT', name: 'North Lincolnshire' },
  { abbreviation: 'KU', name: 'North Somerset' },
  { abbreviation: 'KV', name: 'North Yorkshire' },
  { abbreviation: 'KO', name: 'Northamptonshire' },
  { abbreviation: 'KW', name: 'Northern Ireland' },
  { abbreviation: 'KX', name: 'Northumberland' },
  { abbreviation: 'KZ', name: 'Nottinghamshire' },
  { abbreviation: 'L0', name: 'Orkney and Shetland Isles' },
  { abbreviation: 'L1', name: 'Oxfordshire' },
  { abbreviation: 'L2', name: 'Pembrokeshire' },
  { abbreviation: 'L3', name: 'Perth and Kinross' },
  { abbreviation: 'L4', name: 'Powys' },
  { abbreviation: 'L5', name: 'Redcar and Cleveland' },
  { abbreviation: 'L6', name: 'Renfrewshire' },
  { abbreviation: 'L7', name: 'Rhonda Cynon Taff' },
  { abbreviation: 'L8', name: 'Rutland' },
  { abbreviation: 'L9', name: 'Scottish Borders' },
  { abbreviation: 'LB', name: 'Shetland' },
  { abbreviation: 'LC', name: 'Shropshire' },
  { abbreviation: 'LD', name: 'Somerset' },
  { abbreviation: 'LE', name: 'South Ayrshire' },
  { abbreviation: 'LF', name: 'South Glamorgan' },
  { abbreviation: 'LG', name: 'South Gloucesteshire' },
  { abbreviation: 'LH', name: 'South Lanarkshire' },
  { abbreviation: 'LI', name: 'South Yorkshire' },
  { abbreviation: 'LJ', name: 'Staffordshire' },
  { abbreviation: 'LK', name: 'Stirling' },
  { abbreviation: 'LL', name: 'Stockton On Tees' },
  { abbreviation: 'LM', name: 'Suffolk' },
  { abbreviation: 'LN', name: 'Surrey' },
  { abbreviation: 'LO', name: 'Swansea' },
  { abbreviation: 'LP', name: 'Torfaen' },
  { abbreviation: 'LQ', name: 'Tyne and Wear' },
  { abbreviation: 'LR', name: 'Tyrone' },
  { abbreviation: 'LS', name: 'Vale Of Glamorgan' },
  { abbreviation: 'LT', name: 'Wales' },
  { abbreviation: 'LU', name: 'Warwickshire' },
  { abbreviation: 'LV', name: 'West Berkshire' },
  { abbreviation: 'LW', name: 'West Dunbartonshire' },
  { abbreviation: 'LX', name: 'West Glamorgan' },
  { abbreviation: 'LY', name: 'West Lothian' },
  { abbreviation: 'LZ', name: 'West Midlands' },
  { abbreviation: 'M0', name: 'West Sussex' },
  { abbreviation: 'M1', name: 'West Yorkshire' },
  { abbreviation: 'M2', name: 'Western Isles' },
  { abbreviation: 'M3', name: 'Wiltshire' },
  { abbreviation: 'M4', name: 'Wirral' },
  { abbreviation: 'M5', name: 'Worcestershire' },
  { abbreviation: 'M6', name: 'Wrexham' },
  { abbreviation: 'M7', name: 'York' },
];

export const COMMUNICATION_PREFERENCE_STATUS_LABELS = {
  [communicationPreferenceOptions.ALLOWED]: 'Allowed',
  [communicationPreferenceOptions.DENIED]: 'Denied',
};

export const COMMUNICATION_PREFERENCE_LABELS = {
  [outreachTypes.CALL]: 'Calls',
  [outreachTypes.EMAIL]: 'Emails',
  [outreachTypes.LETTER]: 'Letters',
  [outreachTypes.SMS]: 'SMS',
};

export const TRACK_SCREEN_TYPES = {
  CALLS: 'Calls View',
  EDIT_PATIENT: 'Edit Patient Info',
  EDIT_PCP: 'Edit PCP Info',
  PATIENT: 'Patient Page',
  PATIENT_LIST: 'Patient List',
  PCP_CENTER: 'PCP Center',
  SCRIPT: 'Script',
};

export const PATIENT_INFO_FIELDS = {
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  CITY: 'city',
  COUNTRY: 'country',
  FIRST_NAME: 'firstName',
  HEALTH_ID: 'healthId',
  LANDLINE_NUMBER: 'landlineNumber',
  LAST_NAME: 'lastName',
  MOBILE_NUMBER: 'mobileNumber',
  POSTAL_CODE: 'postalCode',
  STATE_OR_PROVINCE: 'stateOrProvince',
  ZIP_CODE: 'zipCode',
};

export const PATIENT_INFO_LABELS = {
  [PATIENT_INFO_FIELDS.ADDRESS_LINE_1]: 'Address line 1',
  [PATIENT_INFO_FIELDS.ADDRESS_LINE_2]: 'Address line 2',
  [PATIENT_INFO_FIELDS.CITY]: 'City',
  [PATIENT_INFO_FIELDS.COUNTRY]: 'Country',
  [PATIENT_INFO_FIELDS.FIRST_NAME]: 'First name',
  [PATIENT_INFO_FIELDS.HEALTH_ID]: 'ID',
  [PATIENT_INFO_FIELDS.LANDLINE_NUMBER]: 'Home phone number',
  [PATIENT_INFO_FIELDS.LAST_NAME]: 'Last name',
  [PATIENT_INFO_FIELDS.MOBILE_NUMBER]: 'Mobile phone number',
  [PATIENT_INFO_FIELDS.POSTAL_CODE]: 'Postal code',
  [PATIENT_INFO_FIELDS.STATE_OR_PROVINCE]: 'State',
  [PATIENT_INFO_FIELDS.ZIP_CODE]: 'ZIP code',
};

export const LANGUAGE_LABELS = {
  [languages.BEN]: 'Bengali',
  [languages.CZE]: 'Czech',
  [languages.DEU]: 'German',
  [languages.ENG]: 'English',
  [languages.FRA]: 'French',
  [languages.GRE]: 'Greek',
  [languages.GUJ]: 'Gujarati',
  [languages.HIN]: 'Hindi',
  [languages.IND]: 'Indonesian',
  [languages.JPN]: 'Japanese',
  [languages.KOR]: 'Korean',
  [languages.CMN]: 'Mandarin Chinese',
  [languages.MAR]: 'Marathi',
  [languages.POL]: 'Polish',
  [languages.POR]: 'Portuguese',
  [languages.RUS]: 'Russian',
  [languages.SPA]: 'Spanish',
  [languages.SVK]: 'Slovak',
  [languages.ARB]: 'Standard Arabic',
  [languages.TAM]: 'Tamil',
  [languages.TEL]: 'Telugu',
  [languages.TUR]: 'Turkish',
  [languages.URD]: 'Urdu',
  [languages.WUU]: 'Wu Chinese',
  [languages.YUE]: 'Yue Chinese',
  [languages.UNSPECIFIED]: 'Unspecified',
};

export const LANGUAGE_OPTIONS = [
  { label: LANGUAGE_LABELS[languages.BEN], code: languages.BEN },
  { label: LANGUAGE_LABELS[languages.CZE], code: languages.CZE },
  { label: LANGUAGE_LABELS[languages.DEU], code: languages.DEU },
  { label: LANGUAGE_LABELS[languages.ENG], code: languages.ENG },
  { label: LANGUAGE_LABELS[languages.FRA], code: languages.FRA },
  { label: LANGUAGE_LABELS[languages.GRE], code: languages.GRE },
  { label: LANGUAGE_LABELS[languages.GUJ], code: languages.GUJ },
  { label: LANGUAGE_LABELS[languages.HIN], code: languages.HIN },
  { label: LANGUAGE_LABELS[languages.IND], code: languages.IND },
  { label: LANGUAGE_LABELS[languages.JPN], code: languages.JPN },
  { label: LANGUAGE_LABELS[languages.SVK], code: languages.SVK },
  { label: LANGUAGE_LABELS[languages.POL], code: languages.POL },
  { label: LANGUAGE_LABELS[languages.KOR], code: languages.KOR },
  { label: LANGUAGE_LABELS[languages.CMN], code: languages.CMN },
  { label: LANGUAGE_LABELS[languages.MAR], code: languages.MAR },
  {
    label: LANGUAGE_LABELS[languages.POR],
    code: languages.POR,
    common: true,
  },
  {
    label: LANGUAGE_LABELS[languages.RUS],
    code: languages.RUS,
    common: true,
  },
  {
    label: LANGUAGE_LABELS[languages.SPA],
    code: languages.SPA,
    common: true,
  },
  { label: LANGUAGE_LABELS[languages.ARB], code: languages.ARB },
  { label: LANGUAGE_LABELS[languages.TAM], code: languages.TAM },
  { label: LANGUAGE_LABELS[languages.TEL], code: languages.TEL },
  { label: LANGUAGE_LABELS[languages.TUR], code: languages.TUR },
  { label: LANGUAGE_LABELS[languages.URD], code: languages.URD },
  { label: LANGUAGE_LABELS[languages.WUU], code: languages.WUU },
  { label: LANGUAGE_LABELS[languages.YUE], code: languages.YUE },
  {
    label: LANGUAGE_LABELS[languages.UNSPECIFIED],
    code: languages.UNSPECIFIED,
  },
];

export const FILTER_LABELS = {
  language: LANGUAGE_LABELS,
  queueState: QUEUE_STATE_LABELS,
};

export const FILTER_GROUP_LABELS = {
  clinicName: 'Clinic Name',
  cohortName: 'Cohort Name',
  language: 'Language',
};

export const NOTE_METHOD_LABELS = {
  [noteMethods.APP_REVIEW]: 'App review',
  [noteMethods.CALL]: 'Call',
  [noteMethods.CHAT]: 'Chat',
  [noteMethods.COMPLAINTS]: 'Complaints',
  [noteMethods.EMAIL]: 'Email',
  [noteMethods.FEEDBACK]: 'Feedback',
  [noteMethods.KIT]: 'Kit',
  [noteMethods.OTHER]: 'Other',
  [noteMethods.SMS]: 'SMS',
};

export const PATIENT_CONDITION_LABELS = {
  [patientConditionTypes.DIABETES]: 'Diabetes',
  [patientConditionTypes.CKD]: 'CKD',
  [patientConditionTypes.HYPERTENSION]: 'Hypertension',
};

export const GENDER_LABELS = {
  [genders.FEMALE]: 'Female',
  [genders.MALE]: 'Male',
  [genders.OTHER]: 'Other',
};

export const US_AERIAL_TIMEZONES = {
  PACIFIC_TIME: 'US Pacific',
  MOUNTAIN_TIME: 'US Mountain',
  CENTRAL_TIME: 'US Central',
  EASTERN_TIME: 'US Eastern',
  ALASKA_TIME: 'US Alaska',
  HAWAII_TIME: 'US Hawaii',
  PUERTO_RICO: 'US Puerto Rico',
  SAMOA: 'US Samoa',
  CHAMORRO: 'US Chamorro',
  ATLANTIC_TIME: 'US Atlantic',
};

export const US_TIMEZONES_TO_AERIAL_LABELS = {
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_ANCHORAGE]: US_AERIAL_TIMEZONES.ALASKA_TIME,
  [timeZones.AMERICA_PHOENIX]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [timeZones.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.PACIFIC_HONOLULU]: US_AERIAL_TIMEZONES.HAWAII_TIME,
  [timeZones.AMERICA_BOISE]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_INDIANAPOLIS]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_DETROIT]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_PUERTO_RICO]: US_AERIAL_TIMEZONES.ATLANTIC_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [timeZones.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [timeZones.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [timeZones.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
};

export const PCP_CONFIRMATION_STATUS = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
};

export const ADDRESS_FORM_FIELDS = {
  patient: {
    address: {
      addressLine1: {
        name: 'addressLine1',
        errorPaths: ['addressLine1', 'message'],
        css: {
          colSpan: 6,
        },
      },
      addressLine2: {
        name: 'addressLine2',
        errorPaths: ['addressLine2', 'message'],
        css: {
          colSpan: 6,
        },
      },
      city: {
        name: 'city',
        errorPaths: ['city', 'message'],
        css: {
          colSpan: 3,
        },
      },
      stateOrProvince: {
        name: 'stateOrProvince',
        errorPaths: ['stateOrProvince', 'message'],
        css: {
          colSpan: 3,
        },
      },
      postalCode: {
        name: 'postalCode',
        errorPaths: ['postalCode', 'message'],
        css: {
          colSpan: 3,
        },
      },
      country: {
        name: 'country',
        errorPaths: ['country', 'message'],
        css: {
          colSpan: 3,
        },
      },
    },
  },
  pcp: {
    address: {
      addressLine1: {
        name: 'address.addressLine1',
        errorPaths: ['address', 'addressLine1', 'message'],
        css: {
          colSpan: 6,
        },
      },
      addressLine2: {
        name: 'address.addressLine2',
        errorPaths: ['address', 'addressLine2', 'message'],
        css: {
          colSpan: 6,
        },
      },
      city: {
        name: 'address.city',
        errorPaths: ['address', 'city', 'message'],
        css: {
          colSpan: 3,
        },
      },
      stateOrProvince: {
        name: 'address.stateOrProvince',
        errorPaths: ['address', 'stateOrProvince', 'message'],
        css: {
          colSpan: 3,
        },
      },
      postalCode: {
        name: 'address.zipCode',
        errorPaths: ['address', 'zipCode', 'message'],
        css: {
          colSpan: 3,
        },
      },
      country: {
        name: 'address.country',
        errorPaths: ['address', 'country', 'message'],
        css: {
          colSpan: 3,
        },
      },
    },
  },
};

export const PCP_CONFIRMATION_FIELDS_BY_TYPE = {
  npi: {
    textInputProps: {
      name: 'npi',
      label: 'NPI number',
      type: 'number',
    },
    confirmationCheckboxProps: {
      name: 'npiConfirmationStatus',
      label: 'NPI confirmed',
    },
  },
  faxNumber: {
    textInputProps: {
      name: 'faxNumber',
      label: 'Fax number',
    },
    confirmationCheckboxProps: {
      name: 'faxConfirmationStatus',
      label: 'Fax confirmed',
    },
  },
  fullName: {
    textInputProps: {
      name: 'fullName',
      label: 'Full name',
    },
    confirmationCheckboxProps: {
      name: 'confirmationStatus',
      label: 'PCP confirmed',
    },
  },
};

export const CONDITIONAL_CALL_CONTENT_TYPES = {
  endOfService: 'endOfService',
  patientFlowStatus: 'patientFlowStatus',
  scriptDirection: 'scriptDirection',
};
